<template>
  <div
      v-if="!preloader"
      class="preloader"
  >
    <div class="preloader__content">
      <div class="preloader__logo">
        <img src="./assets/Preloader.gif" class="preloader__img">
      </div>
    </div>
  </div>

<!--    <nav>-->
<!--      <router-link to="/">Home</router-link> |-->
<!--      <router-link to="/about">About</router-link>-->
<!--    </nav>-->
  <component :is="layout">
    <router-view/>
  </component>
</template>

<script>

import MainLayout from "@/layout/MainLayout"
import AboutLayout from "@/layout/AboutLayout"
import axios from "axios"

import {useStore} from "vuex"
import {mapGetters} from "vuex"
import {ref, onMounted, computed} from "vue"
import { useRoute } from 'vue-router'

export default {
  MainLayout,
  AboutLayout,
  name: 'App',

  setup() {
    const store = useStore()
    const route = useRoute()
    let preloader = ref()

    const preloadView = () => {
      setTimeout(() => {
        preloader.value = true
      }, 1500)
    }
    preloadView()

    const layout = computed(
        () => {
          return `${route.meta.layout || 'main'}-layout`
        }
    )

    return {
      store,
      // allPoints,
      layout,
      preloadView,
      preloader
    }
  },

}
</script>

<style lang="less">

.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: showPreloader .5s;
  animation: showPreloader .5s;
  background-color: #f5f7fa;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999;

  img {
    height: 140px;
    width: 140px;
  }
}

@-webkit-keyframes showPreloader {
  0% {
    opacity: 1;
    visibility: visible
  }

  to {
    opacity: 1;
    visibility: visible
  }
}

@-webkit-keyframes hidePreloader {
  0% {
    opacity: 1;
    visibility: visible
  }

  to {
    opacity: 0;
    visibility: hidden
  }
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
