<template>
  <div class="blaack container mx-auto flex flex-col items-center bg-gray-100 p-4">
    <router-view/>
  </div>
</template>

<style type="text/css">
  .blaack {
    background: #000 !important;
  }
</style>
