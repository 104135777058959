import axios from "axios";
import {ref} from "vue";

export default {
    namespaced: true,
    state: {
        // urlServer: "https://sponq.ru:5001/",
        urlServer: "http://localhost:5000/",
        points: ref([]),
        total: ref(0),
        status: ref(false),
        endpoints: [
            {
                "name": 'UAE',
                "websiteName": "flonq.ae",
                "websiteUrl": "https://flonq.ae",
                "counter": 0,
            },
            {
                "name": 'Slovenia',
                "websiteName": "flonq.si",
                "websiteUrl": "https://flonq.si",
                "counter": 0
            },
            {
                "name": 'Italy',
                "websiteName": "flonq.it",
                "websiteUrl": "https://flonq.it",
                "counter": 0
            },
            {
                "name": 'Russia',
                "websiteName": "plonq.ru",
                "websiteUrl": "https://plonq.ru",
                "counter": 0
            },
            {
                "name": 'Kazakhstan',
                "websiteName": "plonq.kz",
                "websiteUrl": "https://plonq.kz",
                "counter": 0
            }
        ]
    },
    getters: {
        getPoints(state) {
            return state.points
        }
    },
    mutations: {
        refreshPoints(state, points) {
            points[0].forEach((item, index) => {
                console.log(item)
                if (item.counter) {
                    item.counter = item.counter.replace(/\&nbsp;/g, '')
                    state.total += Number(item.counter)
                    state.endpoints[index].counter = Number(item.counter)
                    state.endpoints[index].status = item.status
                }
            })
            state.points = points
            state.endpoints.unshift({
                "name": "Total",
                "counter": state.total,
            })
        }
    },
    actions: {
        async fetchPoints(ctx) {
            await axios.post(ctx.state.urlServer, ctx.state.endpoints)
                .then((res) => {
                    ctx.state.points.push(res.data)
                })
                .catch((error) => {
                    console.log('PPC')
                    console.error(error);
                });
            ctx.commit('refreshPoints', ctx.state.points)
        },
    },
    modules: {}
}
