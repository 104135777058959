import {createStore} from 'vuex'
import pointsData from './modules/pointsData'

export default createStore({
    state: {
        abc: [],
        // responseData: {}
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        pointsData
    }
})
